import React, { useState, useEffect } from "react"
import { searchBarDiv, categoriesUl, 
    categoriesMainDiv, searchBarInnerDiv, categoriesButton, categoriesButtonRight, categoriesButtonLeft, categoriesTitleP } from './CategoriesBar.module.css'
import SearchBarCategory from "./SearchBarCategory" 

const CategoriesBar = () => {
    const [categoriesShowLeft, setCategoriesShowLeft] = React.useState(false);
    const [categoriesShowRight, setCategoriesShowRight] = React.useState(true);

    function scrollCategoriesRight() {
        var ul = document.getElementById("categoriesUl");
        var div = document.getElementById("categoriesDiv");
        var fullWidth = ul.childNodes[0].offsetWidth*15;
        var currentScroll = ul.scrollLeft + div.offsetWidth;

        if (currentScroll >= fullWidth - div.offsetWidth) {
            currentScroll = fullWidth - div.offsetWidth;
            setCategoriesShowRight(false);
        }

        ul.scroll(currentScroll, 0);
        setCategoriesShowLeft(true);
    }
    
    function scrollCategoriesLeft() {
        var ul = document.getElementById("categoriesUl");
        var div = document.getElementById("categoriesDiv");
        var currentScroll = ul.scrollLeft - div.offsetWidth;

        if (currentScroll < 100) {
            currentScroll = 0;
            setCategoriesShowLeft(false);
        }

        ul.scroll(currentScroll, 0);
        setCategoriesShowRight(true);
    }

    return (
        <div className={searchBarDiv}>
            <div className={searchBarInnerDiv}>
                <p className={categoriesTitleP}>¿Qué te gustaría probar?</p>
                <div id="categoriesDiv" className={categoriesMainDiv}>
                    { categoriesShowRight ? <button className={[categoriesButton, categoriesButtonRight].join(" ")} aria-label="Categorias Anteriores" onClick={scrollCategoriesRight}> 
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M12 3.984l8.016 8.016-8.016 8.016-1.406-1.406 5.578-5.625h-12.188v-1.969h12.188l-5.578-5.625z"></path></svg> 
                    </button> : <></> }
                    { categoriesShowLeft ? <button className={[categoriesButton, categoriesButtonLeft].join(" ")} aria-label="Categorias Siguientes" onClick={scrollCategoriesLeft}> 
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M20.016 11.016v1.969h-12.188l5.578 5.625-1.406 1.406-8.016-8.016 8.016-8.016 1.406 1.406-5.578 5.625h12.188z"></path></svg> 
                    </button> : <></> }
                    <ul id="categoriesUl" className={categoriesUl}>
                        <SearchBarCategory text="Salsa" img="salsa"></SearchBarCategory>
                        <SearchBarCategory text="Galletitas" img="galletitas"></SearchBarCategory>
                        <SearchBarCategory text="Chocolates" img="chocolate"></SearchBarCategory>
                        <SearchBarCategory text="Postre en Vaso" img="postre en vaso"></SearchBarCategory>
                        <SearchBarCategory text="Mermeladas" img="mermelada"></SearchBarCategory>
                        <SearchBarCategory text="Tortas" img="torta"></SearchBarCategory>
                        <SearchBarCategory text="Muffins" img="muffins"></SearchBarCategory>
                        <SearchBarCategory text="Tortas en Taza" img="torta en taza"></SearchBarCategory>
                        <SearchBarCategory text="Mousses" img="mousse"></SearchBarCategory>
                        <SearchBarCategory text="Budines" img="budin"></SearchBarCategory>
                        <SearchBarCategory text="Flanes" img="flan"></SearchBarCategory>
                        <SearchBarCategory text="Masas Fritas" img="masa frita"></SearchBarCategory>
                        <SearchBarCategory text="Panes" img="pan"></SearchBarCategory>
                        <SearchBarCategory text="Rapidas" img="rapida"></SearchBarCategory>
                        <SearchBarCategory text="Tartas" img="tarta"></SearchBarCategory>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default CategoriesBar
