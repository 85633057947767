import { categoriesLi, categoriesImage, categoriesText } from './SearchBarCategory.module.css'
import React from "react"
import { StaticImage } from 'gatsby-plugin-image'
import { navigate } from 'gatsby';

const SearchBarCategory = ({text, img}) => {
    const handleCategoryClick = categoryName => {
        if (categoryName.toLowerCase() === "budin") {
            navigate(`recetas/budin`);
        } else if (categoryName.toLowerCase() === "chocolate") {
            navigate(`recetas/chocolate`);
        } else if (categoryName.toLowerCase() === "flan") {
            navigate(`recetas/flan`);
        } else if (categoryName.toLowerCase() === "galletitas") {
            navigate(`recetas/galletitas`);
        } else if (categoryName.toLowerCase() === "masa frita") {
            navigate(`recetas/masa-frita`);
        } else if (categoryName.toLowerCase() === "mermelada") {
            navigate(`recetas/mermelada`);
        } else if (categoryName.toLowerCase() === "mousse") {
            navigate(`recetas/mousse`);
        } else if (categoryName.toLowerCase() === "muffins") {
            navigate(`recetas/muffins`);
        } else if (categoryName.toLowerCase() === "oreo") {
            navigate(`recetas/oreo`);
        } else if (categoryName.toLowerCase() === "pan") {
            navigate(`recetas/pan`);
        } else if (categoryName.toLowerCase() === "postre en vaso") {
            navigate(`recetas/postre-en-vasitos`);
        } else if (categoryName.toLowerCase() === "rapida") {
            navigate(`recetas/rapida`);
        } else if (categoryName.toLowerCase() === "salsa") {
            navigate(`recetas/salsa`);
        } else if (categoryName.toLowerCase() === "tarta") {
            navigate(`recetas/tarta`);
        } else if (categoryName.toLowerCase() === "torta en taza") {
            navigate(`recetas/torta-en-taza`);
        } else if (categoryName.toLowerCase() === "torta") {
            navigate(`recetas/torta`);
        } else {
            navigate(`busqueda-receta/?search=${categoryName}`);
        }
    }

    return (
        <li className={categoriesLi} onClick={() => handleCategoryClick(img)}>
            { img === "budin" ? <StaticImage className={categoriesImage} src="../../images/categories/budin.png" imgStyle={{objectFit: 'contain'}} alt={text}/> : <></> }
            { img === "chocolate" ? <StaticImage className={categoriesImage} src="../../images/categories/chocolate.png" imgStyle={{objectFit: 'contain'}} alt={text}/> : <></> }
            { img === "flan" ? <StaticImage className={categoriesImage} src="../../images/categories/flan.png" imgStyle={{objectFit: 'contain'}} alt={text}/> : <></> }
            { img === "galletitas" ? <StaticImage className={categoriesImage} src="../../images/categories/galletitas.png" imgStyle={{objectFit: 'contain'}} alt={text}/> : <></> }
            { img === "masa frita" ? <StaticImage className={categoriesImage} src="../../images/categories/masa frita.png" imgStyle={{objectFit: 'contain'}} alt={text}/> : <></> }
            { img === "mermelada" ? <StaticImage className={categoriesImage} src="../../images/categories/mermelada.png" imgStyle={{objectFit: 'contain'}} alt={text}/> : <></> }
            { img === "mousse" ? <StaticImage className={categoriesImage} src="../../images/categories/mousse.png" imgStyle={{objectFit: 'contain'}} alt={text}/> : <></> }
            { img === "muffins" ? <StaticImage className={categoriesImage} src="../../images/categories/muffins.png" imgStyle={{objectFit: 'contain'}} alt={text}/> : <></> }
            { img === "oreo" ? <StaticImage className={categoriesImage} src="../../images/categories/oreo.png" imgStyle={{objectFit: 'contain'}} alt={text}/> : <></> }
            { img === "pan" ? <StaticImage className={categoriesImage} src="../../images/categories/pan.png" imgStyle={{objectFit: 'contain'}} alt={text}/> : <></> }
            { img === "postre en vaso" ? <StaticImage className={categoriesImage} src="../../images/categories/postre en vaso.png" imgStyle={{objectFit: 'contain'}} alt={text}/> : <></> }
            { img === "rapida" ? <StaticImage className={categoriesImage} src="../../images/categories/rapida.png" imgStyle={{objectFit: 'contain'}} alt={text}/> : <></> }
            { img === "salsa" ? <StaticImage className={categoriesImage} src="../../images/categories/salsa.png" imgStyle={{objectFit: 'contain'}} alt={text}/> : <></> }
            { img === "tarta" ? <StaticImage className={categoriesImage} src="../../images/categories/tarta.png" imgStyle={{objectFit: 'contain'}} alt={text}/> : <></> }
            { img === "torta en taza" ? <StaticImage className={categoriesImage} src="../../images/categories/torta en taza.png" imgStyle={{objectFit: 'contain'}} alt={text}/> : <></> }
            { img === "torta" ? <StaticImage className={categoriesImage} src="../../images/categories/torta.png" imgStyle={{objectFit: 'contain'}} alt={text}/> : <></> }
            <h2 className={categoriesText} onClick={() => handleCategoryClick(text)}>{text}</h2>
        </li>
    )
}

export default SearchBarCategory
